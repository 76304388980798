import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { UserProfile } from "@/types/userProfile";
import UserService from "@/services/UserService";
import Vue from "vue";

@Module({ namespaced: true, name: "User" })
class User extends VuexModule {
  private _user: UserProfile | null = null
  private _userExpired = true;

  @Mutation
  public SET_USER(user: UserProfile): void {
    this._user = user;
    Vue.$cookies.set("user_id", this._user?.id || "");
    Vue.$cookies.set("company_name", this._user?.companyName || "");
    this._userExpired = false;
  }

  @Mutation
  public EXPIRE_USER(): void {
    this._userExpired = true;
    Vue.$cookies.remove("user_id");
    Vue.$cookies.remove("company_name");
    this._user = null;
  }

  @Mutation
  public SET_API_KEY(apiKey: string): void {
    if (this._user === null) {
      throw new Error("Attempted setting API key without user profile.");
    }

    this._user.apiKey = apiKey;
  }

  @Action({ rawError: true })
  public async updateUser(newUserData: Partial<UserProfile>): Promise<UserProfile> {
    return await UserService.update(newUserData).then(
      user => {
        this.context.commit("SET_USER", user);
        return Promise.resolve(user);
      },
      error => {
        console.error(error);
        return Promise.reject(error);
      },
    );
  }

  @Action({ rawError: true })
  public async getUser(): Promise<any> {
    return await UserService.get().then(
      user => {
        this.context.commit("SET_USER", user);
        return Promise.resolve(user);
      },
      error => {
        console.error(error);
        this.context.commit("EXPIRE_USER");
        return Promise.reject();
      },
    );
  }

  @Action({ rawError: true })
  public async generateApiKey(): Promise<any> {
    if (this._user === null) {
      throw new Error("Attempted generating API key without user profile.");
    }

    return await UserService.generateApiKey(this._user.id).then(
      apiKey => {
        this.context.commit("SET_API_KEY", apiKey);
      },
      error => {
        console.error(error);
        return Promise.reject(error);
      },
    );
  }

  @Action({ rawError: true })
  public async clearApiKey(): Promise<any> {
    if (this._user === null) {
      throw new Error("Attempted deleting API key without user profile.");
    }

    return await UserService.clearApiKey(this._user.id).then(
      () => {
        this.context.commit("SET_API_KEY", "");
      },
      error => {
        console.error(error);
        return Promise.reject(error);
      },
    );
  }

  @Action({ rawError: true })
  public async updateDaysBeforeVesselDataOutdated(days: number | null): Promise<any> {
    if (this._user === null) {
      throw new Error("Attempted updating setting without user profile.");
    }

    const updatedUser = this._user;
    updatedUser.daysBeforeDataOutdated = days;

    return await UserService.update(updatedUser).then(
      user => {
        this.context.commit("SET_USER", user);
        return Promise.resolve(user);
      },
      error => {
        console.error(error);
        return Promise.reject(error);
      },
    );
  }

  get userProfile(): UserProfile | null {
    return this._user;
  }

  get userName(): string {
    return this._user?.name || "";
  }
  get userTitle(): string {
    return this._user?.title || "";
  }

  get userEmail(): string {
    return this._user?.email || "";
  }
  get userPhone(): string | null {
    return this._user?.phoneNumber || null;
  }
  get userRole(): string | null {
    return this._user?.role || null;
  }
  get userCompanyId(): number | null {
    return this._user?.companyId || null;
  }
  get userCompanyName(): string | null {
    return this._user?.companyName || "";
  }
  get userId(): string {
    return this._user?.id || "";
  }
  get userApiKey(): string {
    return this._user?.apiKey || "";
  }
  get daysBeforeDataOutdated(): number | null {
    return this._user?.daysBeforeDataOutdated ?? null;
  }
  get userExpired(): boolean {
    return this._userExpired;
  }
  get isAdmin(): boolean {
    if (!this._user) return false;
    if (this._user.role === "Administrator") return true;
    return false;
  }
  get isSuperOrAdmin(): boolean {
    if (!this._user) return false;
    const role = this._user.role;
    if (role === "SuperUser" || role === "Administrator") return true;
    return false;
  }
  get isKognifai(): boolean {
    if (!this._user) return false;
    if (this._user.role === "KognifaiUser") return true;
    return false;
  }
}

export default User;
