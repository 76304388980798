import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import EmissionsComplianceClient from "Clients/emissions-compliance-client.js";

@Module({ namespaced: true, name: "EmissionsCompliance" })
class EmissionsCompliance extends VuexModule {
  private _loadingState = false;
  private _loadingEmissionSummaryState = false;
  private _voyageList: any[] = [];
  private _emissionSummary: any[] = [];

  // @Getters
  public get voyageList(): any[] {
    return this._voyageList;
  }

  public get emissionSummary(): any[] {
    return this._emissionSummary;
  }

  public get loadingState(): boolean {
    return this._loadingState;
  }

  public get loadingEmissionSummaryState(): boolean {
    return this._loadingEmissionSummaryState;
  }

  // @Actions
  @Action({ rawError: true })
  public async fetchVoyageList(vesselId: number): Promise<void> {
    try {
      this.context.commit("SET_LOADING_STATE", true);
      const voyageList = await EmissionsComplianceClient.getVoyageList(vesselId);
      this.context.commit("FETCH_VOYAGE_LIST", voyageList);
      this.context.commit("SET_LOADING_STATE", false);
    } catch (error) {
      throw ({ message: "Failed to fetch voyages summary -> ", error });
    }
  }

  @Action({ rawError: true })
  public async fetchEmissionSummary(vesselId: number): Promise<void> {
    try {
      this.context.commit("SET_EMISSION_SUMMARY_LOADING_STATE", true);
      const emissionSummary = await EmissionsComplianceClient.getEmissionSummary(vesselId);
      this.context.commit("FETCH_EMISSION_SUMMARY", emissionSummary);
      this.context.commit("SET_EMISSION_SUMMARY_LOADING_STATE", false);
    } catch (error) {
      throw ({ message: "Failed to fetch emission summary -> ", error });
    }
  }

  @Action({ rawError: true })
  public async uploadFiles(payload: FormData): Promise<{ files: []; skippedFiles: []; }> {
    try {
      const response: { files: []; skippedFiles: []; } = await EmissionsComplianceClient.uploadFiles(payload);
      return response;
    } catch (error) {
      throw ({ message: "Failed to upload files for voyage summary table -> ", error });
    }
  }

  @Action({ rawError: true })
  public async downloadReport(request: { vesselId: number; filename: string | null }): Promise<void> {
    try {
      EmissionsComplianceClient.downloadReport(request);
    } catch (error) {
      throw ({ message: "Failed to download voyage report -> ", error });
    }
  }

  // @Mutations

  @Mutation
  public FETCH_VOYAGE_LIST(voyageList: any[]): void {
    this._voyageList = voyageList;
  }

  @Mutation
  public FETCH_EMISSION_SUMMARY(emissionSummary: any[]): void {
    this._emissionSummary = emissionSummary;
  }

  @Mutation
  public SET_LOADING_STATE(loading: boolean): void {
    this._loadingState = loading;
  }

  @Mutation
  public SET_EMISSION_SUMMARY_LOADING_STATE(loading: boolean): void {
    this._loadingEmissionSummaryState = loading;
  }

}

export default EmissionsCompliance;
