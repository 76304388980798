import net from "@/Scripts/app/net";
/* eslint-disable */
class DataQualityClient {
  constructor() { }
  getFleetOverview() {
    return net.get("/DataQuality/FleetOverview");
  }
  getDataOutage() {
    return net.get("/DataQuality/DataOutage");
  }
  getDataOutageConfig() {
    return net.get("/DataQuality/DataOutage/Configuration");
  }
  updateDataOutageConfig(config) {
    return net.put("/DataQuality/DataOutage/Configuration", config);
  }
  getCompanyOutliers(query) {
    // @params query = { companyId, fromDate?, toDate? };
    return net.get(`/DataQuality/CompanyOutliers`, query);
  }
  getFeatureConfigs(query) {
    // @params query = { vesselId };
    return net.get(`/DataQuality/Outliers/Config`, query);
  }
  updateFeatureConfigs(config) {
    return net.post(`/DataQuality/Outliers/Config`, config);
  }
  getFeatureValues() {
    return net.get(`/datafeature`);
  }
  updateOutliersAcknowledge(tags) {
    return net.put("/DataQuality/Outliers/TakeAction/Acknowledge", tags);
  }
  updateOutliersRemove(tags) {
    return net.put("/DataQuality/Outliers/TakeAction/Delete", tags);
  }

  getSpeedLog(query) {
    query.featureName = "Speed Through Water";
    return net.get("/DataQuality/SpeedLog", query);
  }
  getDerivedSpeedThroughWater(query) {
    return net.get("/DataQuality/SpeedLog/DerivedSpeedThroughWater", query);
  }

  getSpeedThroughWater(query) {
    return net.get("/DataQuality/SpeedLog/SpeedThroughWater", query);
  }
}

export default new DataQualityClient();
