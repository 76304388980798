import net from "@/Scripts/app/net";
/* eslint-disable */
class EmissionsComplianceClient {
  constructor() {}

  getVoyageList(vesselid) {
    return net.get(`/EmissionCompliance/voyages/${vesselid}`);
  }

  getEmissionSummary(vesselid) {
    return net.get(`/EmissionCompliance/emission-summary/${vesselid}`);
  }

  uploadFiles(payload) {
    // @param payload { vesselId; files; }
    var url = "/EmissionCompliance/upload-multiple"
    return net.fileUpload(url, payload);
  }

  downloadReport(params) {
    // @param params { vesselId; filename; }
    var url = `/EmissionCompliance/download-voyage-file?vesselId=${params.vesselId}&fileName=${params.filename}`
    return net.fileDownload(url, "GET");
  }
}

export default new EmissionsComplianceClient();
